<template>
  <section class="dashboard">
   
    <div class="page-header">
      <h3 class="page-title">
        <span class="page-title-icon bg-gradient-primary text-white mr-2">
          <i class="mdi mdi-check"></i>
        </span> Editar desarrollo</h3>
        
        <!--
          <nav aria-label="breadcrumb">
            <ul class="breadcrumb">
              <li class="breadcrumb-item active" aria-current="page">
                <span></span>Vista general<i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
              </li>
            </ul>
          </nav>
        -->
    </div>


    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title"><span v-text="item_name"></span></h4>
            <formDevelopment @afterSave="afterSave" @onData="onData" :id="parseInt($route.params.id)"/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import formDevelopment from "./_form";




export default {
  routeName:'developments',
  name:"updateDevelopment",
  components: {
    formDevelopment

  },
  data(){

    return {

      item_name:''
      
    }


  },
  methods:{

    onData(data){

      this.item_name=data.name;

    },
    afterSave(){

      return;
      //this.$route.push(this.me());

    }

  } 

}



</script>


